import React, { Component } from 'react'
import RootComponent from './components/RootComponent.js'

class App extends Component {
  render () {
    return (
      <RootComponent/>
    );
  }
}

export default App;
